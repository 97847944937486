<template>
  <div>
    <b-row class="pb-1">
      <b-col>
        <b-card-text
          style="margin-top: 10px"
          class="text-bold"
        >
          <feather-icon
            icon="ClockIcon"
            size="20"
          />  {{ $t("user-time-spent.title") }}
        </b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <div class="custom-search d-flex justify-content-end w-100">
          <b-form-group class="w-100">
            <label class="mr-1">{{ $t('messages.seachLabel') }}</label>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('search-user')"
                debounce="500"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
      <b-col md="2">
        <div class="custom-search d-flex justify-content-end w-100">
          <b-form-group class="w-100">
            <label class="mr-1">{{ $t('user-types.school') }}</label>
            <b-card body-class="p-0">
              <v-select
                v-model="school_id"
                transition=""
                :clearable="false"
                label="fullname"
                :reduce="(value) => value.id"
                :options="schoolsLists"
                :placeholder="$t('all-school')"
              />
            </b-card>
          </b-form-group>
        </div>
      </b-col>
      <b-col md="2">
        <div class="custom-search d-flex justify-content-end w-100">
          <b-form-group class="w-100">
            <label class="mr-1">{{ $t('labels.classroom') }}</label>
            <b-card body-class="p-0">
              <v-select
                v-model="classRoomId"
                transition=""
                :clearable="false"
                label="text"
                :reduce="(value) => value.value"
                :options="schoolClassRooms"
                :placeholder="$t('all-classrooms')"
              />
            </b-card>
          </b-form-group>
        </div>
      </b-col>
      <b-col md="2">
        <div class="custom-search d-flex justify-content-end w-100">
          <b-form-group class="w-100">
            <label class="mr-1">{{ $t('labels.timezone') }}</label>
            <div class="d-flex align-items-center">
              <b-form-select
                v-model="selectedTimezone"
                :options="timezones"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
      <b-col md="3">
        <div class="custom-search d-flex justify-content-end w-100">
          <b-form-group class="w-100">
            <label class="mr-1">{{ $t('labels.date') }}</label>
            <div class="d-flex align-items-center">
              <b-form-datepicker
                v-model="searchDate"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                locale="en"
                :max="today"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-tabs pills>
      <b-tab
        :title="$t('labels.day')"
        active
        lazy
      >
        <b-overlay :show="showOverlay">
          <day-table
            :search-date="searchDate"
            :format-time="formatTime"
            :class-room="classRoomId"
            :get-random-variant="getRandomVariant"
            :get-initials="getInitials"
            :selected-timezone="selectedTimezone"
            :search-term="searchTerm"
            @toogleOverlay="(value)=> showOverlay = value"
          />
        </b-overlay>
      </b-tab>
      <b-tab
        :title="$t('labels.week')"
        lazy
      >
        <b-overlay :show="showOverlay">
          <week-table
            :rows="rows"
            :search-date="searchDate"
            :format-time="formatTime"
            :class-room="classRoomId"
            :get-random-variant="getRandomVariant"
            :get-initials="getInitials"
            :search-term="searchTerm"
            :school-time="schoolTime"
            :selected-timezone="selectedTimezone"
            @toogleOverlay="(value)=> showOverlay = value"
          />
        </b-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BFormGroup, BCardText, BRow, BCol, BOverlay, BFormDatepicker, BTab, BTabs, BFormSelect, BFormInput, BCard,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import vSelect from 'vue-select'
import DayTable from './Day.vue'
import WeekTable from './Week.vue'

export default {
  components: {
    BFormGroup,
    BCardText,
    BCard,
    BRow,
    BCol,
    DayTable,
    BOverlay,
    BFormDatepicker,
    BTab,
    BTabs,
    BFormSelect,
    WeekTable,
    BFormInput,
    vSelect,
  },
  data() {
    const date = new Date()
    const padNum = num => (`0${num}`).slice(-2)
    const auth = getUserData()
    return {
      searchTerm: '',
      rows: [],
      searchDate: `${date.getFullYear()}-${padNum(date.getMonth() + 1)}-${padNum(date.getDate())}`,
      showOverlay: false,
      paginationData: {},
      selectedTimezone: auth.timezone || null,
      timezones: [
        { value: null, text: 'UTC' },
        { value: 'Asia/Kathmandu', text: 'Asia/Kathmandu' },
        { value: 'Asia/Seoul', text: 'South Korea/Seoul' },
      ],
      classRoomsList: [],
      classRoomId: null,
      today: date,
      schoolsLists: [],
      school_id: null,
    }
  },
  computed: {
    schoolClassRooms() {
      // eslint-disable-next-line eqeqeq
      return this.classRoomsList.filter(item => item.school?.id == this.school_id)
    },
    schoolTime() {
      // eslint-disable-next-line eqeqeq
      return this.schoolsLists.find(item => item.id == this.school_id)?.schoolTime || 24
    },
  },
  watch: {
    school_id() {
      this.classRoomId = null
    },
  },
  mounted() {
    this.getSchoolUsers()
    this.getClassRoom()
  },
  methods: {
    getRandomVariant() {
      const a = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']
      return a[Math.floor(a.length * Math.random())]
    },
    getInitials(name) {
      const names = name.split(' ')
      let initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    },
    formatTime(value) {
      const sec = parseInt(value, 10) // convert value to number if it's string
      if (sec < 0) return '0m 0s'
      const hours = Math.floor(sec / 3600) // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60) // get minutes
      // add 0 if value < 10; Example: 2 => 02
      if (minutes < 10) { minutes = `0${minutes}` }
      return `${hours}h ${minutes}m` // Return is HH : MM : SS
    },
    getSchoolUsers() {
      useApollo
        .getSchoolUsersWithSchoolTime()
        .then(response => {
          const res = response.data?.schools?.data

          this.schoolsLists = res.map(e => ({
            id: parseInt(e.id, 10),
            fullname: e.fullname,
            schoolTime: e.schoolTime,
          }))

          if (this.isASchool) {
            this.school_id = this.self.id
          } else {
            this.school_id = this.schoolsLists[0].id
          }
        })
    },
    getClassRoom() {
      useApollo.getMyClassRoom().then(response => {
        this.classRoomsList = [{ value: null, text: 'All Classrooms' }, ...response.data.rooms.data]
      })
    },
  },
}
</script>
<style>
.avatar-status-indicator{
  border-radius: 50%; width: 12px; height: 12px; position: absolute; right: 0; bottom: 0;
}
.avatar-online {
  background: #28c76f;
}
.avatar-offline {
  background: #82868b;
}
.nickname{
  font-size:14px;
  font-style:italic;
  margin-top:-16px;
}
</style>
